import { Button, Modal } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { InputField } from '../../../atoms/InputField';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { Spinner } from '../../../atoms/Spinner';
import { CommonAlert } from '../../../atoms/Alert';
import { useAppDispatch } from '../../../../redux/store';
import {
  clearFetchPatientInsuranceSubscriberResponse,
  getInsuranceSubscriberRequest,
  getPatientInsuranceSubscriberSelector,
} from '../../../../redux/slices/patient/getInsuranceSubscriberSlice';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { Pagination } from '../../../pagination/Pagination';
import { AddNewSubscriberModal } from './AddNewSubscriberModal';
import moment from 'moment';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'DOB',
    dataIndex: 'dob',
    key: 'dob',
    render: (text: any) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
];

export const RelationshipModal = ({ visibleRelationshipModal, setVisibleRelationshipModal, addRow }: any) => {
  const dispatch = useAppDispatch();
  const { fetchPatientInsuranceSubscriberStatus, fetchPatientInsuranceSubscriberData } = useAppSelector(
    getPatientInsuranceSubscriberSelector
  );

  const [formData, setFormData] = useState<any>({});
  const [selectAddNew, setSelectAddNew] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [fetchDataLoading, setFetchDataLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);

  useEffect(() => {
    setFetchDataLoading(true);
    clearStates();
    getSubscriberData(0);
  }, []);

  useEffect(() => {
    if (fetchPatientInsuranceSubscriberStatus === 'SUCCESS') {
      // console.log('=== fetchPatientInsuranceSubscriberData ===', fetchPatientInsuranceSubscriberData);
      setTotalPages(fetchPatientInsuranceSubscriberData?.totalPages);
      setTotalItems(fetchPatientInsuranceSubscriberData?.totalItems);
      setCurrentPage(fetchPatientInsuranceSubscriberData?.currentPage);
      setDataSource(fetchPatientInsuranceSubscriberData?.items);
      setFetchDataLoading(false);
    } else if (fetchPatientInsuranceSubscriberStatus === 'FAILED') {
      setFetchDataLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchPatientInsuranceSubscriberResponse());
  }, [fetchPatientInsuranceSubscriberStatus]);

  const clearStates = () => {
    // setDataSource([]);
    setFormData({});
    setCurrentPage(0);
    setTotalItems(0);
    setTotalPages(0);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleFieldValueChange = (e: any) => {
    // setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    const { name, value } = e.target;

    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if (
      (!updatedFormData?.fName || updatedFormData?.fName?.trim() === '') &&
      (!updatedFormData?.lName || updatedFormData?.lName?.trim() === '')
    ) {
      setIsSearchEnabled(false);
    } else {
      setIsSearchEnabled(true);
    }
  };

  const getSubscriberData = (page: number) => {
    const obj = {
      page: page ?? currentPage,
      size: PAGE_SIZE,
      fName: formData?.fName ?? '',
      lName: formData?.lName ?? '',
    };
    dispatch(getInsuranceSubscriberRequest(obj));
  };

  const handleSearch = () => {
    getSubscriberData(0);
  };

  const handleAddRow = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    addRow(dataSet);
    setAlertObj({
      color: 'success',
      message: 'Subscriber Selected !',
      error: false,
    });
    setVisibleAlert(true);
    setTimeout(() => {
      setAlertObj({
        color: '',
        message: '',
        error: false,
      });
      setVisibleAlert(false);
      setVisibleRelationshipModal(false);
    }, 1000);
  };

  const onPageChange = (page: any) => {
    // console.log('=== changed page ===', page);
    setCurrentPage(page - 1);
    getSubscriberData(page - 1);
  };

  const onCloseAddNewModal = (close: boolean) => {
    setSelectAddNew(close);
  };

  const handleReset = () => {
    setFormData({});
    setIsSearchEnabled(false);
    const obj = {
      page: 0,
      size: PAGE_SIZE,
      fName: '',
      lName: '',
    };
    dispatch(getInsuranceSubscriberRequest(obj));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  return (
    <>
      <Modal show={visibleRelationshipModal} onClose={() => setVisibleRelationshipModal(false)}>
        <Modal.Header>Search Subscriber</Modal.Header>
        <Modal.Body>
          {visibleAlert && !alertObj?.error && (
            <CommonAlert
              color={alertObj?.color}
              message={alertObj?.message}
              onClose={handleAlertClose}
              alertClassName="w-1/2"
            />
          )}
          <div className="w-28 ml-auto">
            <CommonButton
              type="button"
              label="Add New"
              buttonType="primary"
              onClick={() => setSelectAddNew(true)}
            ></CommonButton>
          </div>
          <div className="grid md:grid-cols-2 gap-5 mt-6">
            <InputField
              label="First Name"
              name="fName"
              onChange={handleFieldValueChange}
              value={formData?.fName ?? ''}
            />
            <InputField
              onChange={handleFieldValueChange}
              name="lName"
              label="Last Name"
              value={formData?.lName ?? ''}
            />
          </div>
          <div className="grid md:grid-cols-2 gap-5 mt-6">
            <div className="col-span-1"></div>
            <div className="col-span-1 flex justify-end space-x-2">
              <CommonButton type="button" label={'Reset'} buttonType="secondary" onClick={handleReset} />
              <CommonButton
                type="button"
                label={'Search'}
                buttonType="primary"
                onClick={handleSearch}
                disabled={!isSearchEnabled}
              />
            </div>
          </div>

          {fetchDataLoading ? (
            <Spinner />
          ) : (
            <>
              {dataSource && dataSource?.length > 0 && (
                <>
                  <TableContent
                    enableActions={true}
                    columns={columns}
                    dataSource={dataSource}
                    enableAdd={true}
                    onHandleAdd={handleAddRow}
                  />

                  <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
                </>
              )}
            </>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <CommonButton
            type="button"
            label={'Cancel'}
            buttonType="secondary"
            onClick={() => () => setVisibleRelationshipModal(false)}
          />
          <CommonButton type="button" label={'Add New'} buttonType="primary" onClick={() => setSelectAddNew(true)} />
        </Modal.Footer> */}
      </Modal>
      {selectAddNew && (
        <AddNewSubscriberModal
          visibleNewSubscriberModal={selectAddNew}
          setVisibleNewSubscriberModal={onCloseAddNewModal}
        />
      )}
    </>
  );
};
