import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { NestedTableContent } from '../../components/table/NestedTableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { ProcedureProductivityReportAdvanceSearch } from '../../components/tableSearch/ProcedureProductivityReportAdvanceSearch';

import {
  getProcedureProductivityReportRequest,
  getProcedureProductivityReportSelector,
  clearFetchProcedureProductivityReportResponse,
} from '../../redux/slices/management/procedureProductivityReport/getProcedureProductivityReportSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';
import {
  getSystemFacilityRequest,
  getSystemFacilitiesSelector,
} from '../../redux/slices/system/getSystemFacilitiesSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Month',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: 'Facility',
    dataIndex: 'facility',
    key: 'facility',
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'CPT Code',
    dataIndex: 'cpt',
    key: 'cpt',
  },
  {
    title: 'Month to Date',
    key: 'monthToDate',
    children: [
      { title: 'Visits', dataIndex: 'monthlyVisits', key: 'monthlyVisits' },
      {
        title: 'Charges',
        dataIndex: 'monthlyCharges',
        key: 'monthlyCharges',
        render: (text: number) => formatCurrency(text ?? 0),
      },
      {
        title: 'Payments',
        dataIndex: 'monthlyPayments',
        key: 'monthlyPayments',
        render: (text: number) => formatCurrency(text ?? 0),
      },
      {
        title: 'Adjustments',
        dataIndex: 'monthlyAdjustments',
        key: 'monthlyAdjustments',
        render: (text: number) => formatCurrency(text ?? 0),
      },
    ],
  },
  {
    title: 'Year to Date',
    key: 'yearToDate',
    children: [
      { title: 'Visits', dataIndex: 'yearlyVisits', key: 'yearlyVisits' },
      {
        title: 'Charges',
        dataIndex: 'yearlyCharges',
        key: 'yearlyCharges',
        render: (text: number) => formatCurrency(text ?? 0),
      },
      {
        title: 'Payments',
        dataIndex: 'yearlyPayments',
        key: 'yearlyPayments',
        render: (text: number) => formatCurrency(text ?? 0),
      },
      {
        title: 'Adjustments',
        dataIndex: 'yearlyAdjustments',
        key: 'yearlyAdjustments',
        render: (text: number) => formatCurrency(text ?? 0),
      },
    ],
  },
];

const dummyDataSource = [
  {
    key: '1',
    month: 'January 2024',
    facility: 'City Hospital',
    provider: 'John Doe',
    cpt: '99213',
    monthlyVisits: 120,
    monthlyCharges: 30000,
    monthlyPayments: 20000,
    monthlyAdjustments: 5000,
    yearlyVisits: 1000,
    yearlyCharges: 250000,
    yearlyPayments: 180000,
    yearlyAdjustments: 30000,
  },
  {
    key: '2',
    month: 'February 2024',
    facility: 'Green Valley Clinic',
    provider: 'Jane Smith',
    cpt: '99397',
    monthlyVisits: 90,
    monthlyCharges: 22000,
    monthlyPayments: 15000,
    monthlyAdjustments: 4000,
    yearlyVisits: 850,
    yearlyCharges: 210000,
    yearlyPayments: 140000,
    yearlyAdjustments: 25000,
  },
  {
    key: '3',
    month: 'March 2024',
    facility: 'Sunrise Medical Center',
    provider: 'Michael Brown',
    cpt: '99214',
    monthlyVisits: 100,
    monthlyCharges: 25000,
    monthlyPayments: 18000,
    monthlyAdjustments: 3500,
    yearlyVisits: 950,
    yearlyCharges: 230000,
    yearlyPayments: 170000,
    yearlyAdjustments: 28000,
  },
  {
    key: '4',
    month: 'April 2024',
    facility: 'Lakeside Hospital',
    provider: 'Emily Davis',
    cpt: '99204',
    monthlyVisits: 110,
    monthlyCharges: 27000,
    monthlyPayments: 19000,
    monthlyAdjustments: 4500,
    yearlyVisits: 1020,
    yearlyCharges: 240000,
    yearlyPayments: 175000,
    yearlyAdjustments: 32000,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'procedureProductivityReport',
    label: 'Procedure Productivity Report',
    status: 'active',
    link: 'procedure-productivity',
  },
];

export const ProcedureProductivityReportPage = () => {
  const dispatch = useAppDispatch();

  const {
    fetchProcedureProductivityReportStatus,
    fetchProcedureProductivityReportData,
    fetchProcedureProductivityReportLoading,
  } = useAppSelector(getProcedureProductivityReportSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const currentYear = moment().year();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({ year: currentYear.toString() });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (systemFacilityData?.length === 0 || systemProvidersProviderData?.length === 0) {
      fetchSystemFacilities();
      fetchSystemProviders();
    }
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchProcedureProductivityReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchProcedureProductivityReportStatus === 'SUCCESS') {
      setDataSource(fetchProcedureProductivityReportData?.items);
      setCurrentPage(fetchProcedureProductivityReportData?.currentPage);
      setTotalPages(fetchProcedureProductivityReportData?.totalPages);
    } else if (fetchProcedureProductivityReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchProcedureProductivityReportResponse());
    }
  }, [fetchProcedureProductivityReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchProcedureProductivityReport(page - 1);
  };

  const fetchProcedureProductivityReport = async (pageNumber = currentPage) => {
    dispatch(getProcedureProductivityReportRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const fetchSystemFacilities = async () => {
    dispatch(getSystemFacilityRequest());
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    if (!updatedData?.providerType || updatedData?.providerType === '') {
      delete updatedData.providerType;
    } else {
      updatedData.providerType = parseInt(updatedData?.providerType, 10);
    }

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.facilityId || updatedData?.facilityId === '') {
      delete updatedData.facilityId;
    } else {
      updatedData.facilityId = parseInt(updatedData?.facilityId, 10);
    }

    if (!updatedData?.procedureType || updatedData?.procedureType === '') {
      delete updatedData.procedureType;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Procedure Productivity Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      <ProcedureProductivityReportAdvanceSearch onSubmit={handleSearch} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchProcedureProductivityReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <NestedTableContent columns={columns} dataSource={dummyDataSource} />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
