import { Button, Modal } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { InputField } from '../../../atoms/InputField';
import { CommonButton } from '../../../atoms/CommonButton';
import { Calendar } from '../../../atoms/Calendar';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';
import { SelectInput } from '../../../atoms/SelectInput';
import { sexStatus } from '../../../../constants/userConstants';
import moment from 'moment';

export const AddNewSubscriberModal = ({ visibleNewSubscriberModal, setVisibleNewSubscriberModal }: any) => {
  const [formData, setFormData] = useState<any>({});

  const handleFieldValueChange = (e: any) => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleOnChangeDate = (name: string, date: any) => {
    setFormData((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  const onSubmit = () => {};

  const onCloseModal = () => {
    setFormData({});
    setVisibleNewSubscriberModal(false);
  };

  return (
    <Modal show={visibleNewSubscriberModal} onClose={onCloseModal}>
      <Modal.Header>Add Subscriber</Modal.Header>
      <Modal.Body>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <InputField
            label="First Name"
            name="firstName"
            onChange={handleFieldValueChange}
            value={formData?.firstName ?? ''}
          />
          <InputField
            onChange={handleFieldValueChange}
            name="lastName"
            label="Last Name"
            value={formData?.lastName ?? ''}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          {/* <Calendar onSelectedDateChanged={(date) => handleOnChangeDate('dob', date)} name="dob" label="DOB" /> */}
          <DatePickerField
            label="DOB"
            name="dob"
            selectedDate={formatDatePickerDate(formData?.dob)}
            onChange={(date: Date | null) => {
              if (date) {
                handleOnChangeDate('dob', date);
              }
            }}
          />
          <SelectInput label={'Gender'} name="gender" onChange={handleFieldValueChange} options={sexStatus} />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <InputField
            label="Email"
            name="email"
            onChange={handleFieldValueChange}
            // value={formData?.email ?? ''}
          />
          <InputField
            onChange={handleFieldValueChange}
            name="phone"
            label="Phone"
            // value={formData?.phone ?? ''}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CommonButton type="button" label={'Cancel'} buttonType="secondary" onClick={onCloseModal} />
        <CommonButton type="button" label={'Add'} buttonType="primary" onClick={onSubmit} />
      </Modal.Footer>
    </Modal>
  );
};
