import { useEffect, useState } from 'react';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { TableContent } from '../../../table/TableContent';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';
import { Pagination } from '../../../pagination/Pagination';

import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import moment from 'moment';

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MMMM D, YYYY h:mm A') : ''),
  },
];

const dataSource = [
  {
    firstName: 'Brian',
    lastName: 'Smith',
    phone: '0782120021',
    createdAt: '2023-05-06T11:20:00Z',
  },
  {
    firstName: 'Alice',
    lastName: 'Johnson',
    phone: '0718821990',
    createdAt: '2023-05-06T08:45:00Z',
  },
  {
    firstName: 'David',
    lastName: 'Williams',
    phone: '0773282910',
    createdAt: '2023-05-06T23:59:00Z',
  },
  {
    firstName: 'Grace',
    lastName: 'Wilson',
    phone: '0712930033',
    createdAt: '2023-05-06T05:05:00Z',
  },
];
export const InsuranceProviders = ({ mode }: any) => {
  return (
    <>
      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
          />
        )}
      </div>
      <TableContent columns={columns} dataSource={dataSource} />
    </>
  );
};
