import { useState } from 'react';
import moment from 'moment';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { ClaimERAFilesHeader } from '../../../components/billing/claimERAFiles/ClaimERAFilesHeader';
import { ClaimedMDERAPayementsHeader } from '../../../components/billing/claimedMDERAPayments/ClaimedMDERAPayementsHeader';
import { validDateFormat } from '../../../utils/commonFunctions';

const columns = [
  {
    title: 'ClaimEra ID',
    dataIndex: 'claimEraId',
    key: 'claimEraId',
  },
  {
    title: 'ERA ID',
    dataIndex: 'eraId',
    key: 'eraId',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Paid Date',
    dataIndex: 'paidDate',
    key: 'paidDate',
  },
  {
    title: 'Paid Amount',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
  },
  {
    title: 'Posted Amount',
    dataIndex: 'postedAmount',
    key: 'postedAmount',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
  },
  {
    title: 'Payer ID',
    dataIndex: 'payerId',
    key: 'payerId',
  },
  {
    title: 'Payer Name',
    dataIndex: 'payerName',
    key: 'payerName',
  },
  {
    title: 'Provider NPI',
    dataIndex: 'providerNpi',
    key: 'providerNpi',
  },
  {
    title: 'Received Time',
    dataIndex: 'receivedTime',
    key: 'receivedTime',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'Posted Date',
    dataIndex: 'postedDate',
    key: 'postedDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
];

const dataSource = [
  {
    claimEraId: 'CE123456',
    eraId: 'ERA123456',
    checkNumber: '12345678',
    paidDate: '2024-05-15',
    paidAmount: '$1500.00',
    postedAmount: '$1450.00',
    balance: '$50.00',
    payerId: 'PAYER123',
    payerName: 'Insurance ABC',
    providerNpi: 'NPI1234567',
    receivedTime: '2024-05-16T10:00:00Z',
    postedDate: '2024-05-17T10:00:00Z',
  },
];

const reportFilters = {
  month: 'Select Month',
  eraId: '',
  checkNumber: '',
};

export const months = [
  { id: '05-2023', name: 'May - 2023' },
  { id: '06-2023', name: 'June - 2023' },
  { id: '07-2023', name: 'July - 2023' },
  { id: '08-2023', name: 'August - 2023' },
  { id: '09-2023', name: 'September - 2023' },
  { id: '10-2023', name: 'October - 2023' },
  { id: '11-2023', name: 'November - 2023' },
  { id: '12-2023', name: 'December - 2023' },
  { id: '01-2024', name: 'January - 2024' },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'claimMdEra', label: 'Claim MD ERA Payments', status: 'active', link: 'claimmd-era' },
];

export const ClaimMDERAPaymentsPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Claim MD ERA Payments" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ClaimedMDERAPayementsHeader reportFilters={reportFilters} totalERA={'3550'} months={months} />
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {dataSource?.length > 0 ? (
        <div className="overflow-x-auto">
          <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
        </div>
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
